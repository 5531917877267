import React, { useState } from "react";
import Header from "./components/Header/Header";

import style from "./App.module.scss";

let admin = String(window.location).split("?")[1] === "morgunov";
let apiImageUrl = "https://morgunov.online";

function App() {
  return (
    <div className={style.wrapper}>
      <Header />
    </div>
  );
}

export default App;
export { admin };
export { apiImageUrl };
