import React from "react";
import style from "./Button.module.scss";

interface ButtonProps {
  children?: React.ReactNode;
  before?: React.ReactNode;
  after?: React.ReactNode;
  className?: boolean;
  stretched?: boolean;
  mode?: string;
  disabled: boolean;
}

const Button = ({
  children,
  mode,
  stretched,
  before,
  after,
  className,
  disabled,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={`
        ${style.container}
        ${mode === "primary" && style.primary}
        ${mode === "white" && style.white}
        ${stretched && style.stretched}
        ${disabled && style.disabled}
      `}
      {...props}
      disabled={disabled}
    >
      <div className={style.before}>{before}</div>
      <div className={style.text}>{children}</div>
      <div className={style.after}>{after}</div>
    </button>
  );
};

export default Button;
