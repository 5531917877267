import style from "./SocialBlock.module.scss";

import { ReactComponent as Telegram_Logo } from "../../../../img/Telegram_Logo.svg";
import { ReactComponent as Viber_Logo } from "../../../../img/Viber_Logo.svg";
import { ReactComponent as WhatsApp_Logo } from "../../../../img/WhatsApp_Logo.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";

const SocialBlock = () => {

  const [phone, setPhone] = useState("");

  async function getData() {
    const {data} = await axios.get('/getData');
    setPhone((data.number.text).replaceAll(' ', '').replaceAll('-', '').replaceAll('+', ''));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={style.wrapper}>
      <Link className={style.social_item} to="https://t.me/Checkandcheck" target="_blank">
        <Telegram_Logo />
      </Link>
      <Link
        className={style.social_item}
        to={`viber://add?number="+${phone}`}
        target="_blank"
      >
        <Viber_Logo />
      </Link>
      <Link
        className={style.social_item}
        to={`https://wa.me/${phone}`}
        target="_blank"
      >
        <WhatsApp_Logo />
      </Link>
    </div>
  );
};

export default SocialBlock;
