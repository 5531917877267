import SocialBlock from "./HeaderComponents/SocialBlock/SocialBlock";
import Navbar from "./HeaderComponents/Navbar/Navbar";

import style from "./Header.module.scss";

const Header = () => {
  return (
    <div className={style.wrapper}>
      <SocialBlock />
      <Navbar />
    </div>
  );
};

export default Header;
