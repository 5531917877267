import { useState } from "react";

import Home from "../../../../pages/Home/Home";
import Documents from "../../../../pages/Documents/Documents";

import NavbarItem from "../NavbarItem/NavbarItem";

import style from "./Navbar.module.scss";

const Navbar = () => {
  const nav = [
    {
      title: "Главная",
      link: "#home",
    },
    {
      title: "О нас",
      link: "#about",
    },
    {
      title: "Отчётные документы",
      link: "/documents",
    },
    {
      title: "Контакты",
      link: "#contacts",
    },
  ];

  const [menuActive, setMenuActive] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname); // Хранит текущий путь

  const navigate = (path) => {
    setCurrentPath(path); // Изменяем текущий путь
    window.history.pushState({}, "", path); // Обновляем URL в браузере
  };

  // Определяем, какой компонент отображать в зависимости от текущего пути
  const renderContent = () => {
    switch (currentPath) {
      case "/":
        return <Home />;
      case "/documents":
        return <Documents />;
      default:
        return <Home />; // Можно сделать страницу 404 для несуществующих путей
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <header className={style.wrapper}>
        <div className={style.logo_wrapper}>ComingInn</div>
        <nav className={style.nav_wrapper}>
          <button className={style.nav_item} onClick={() => navigate("/")}>
            Главная
          </button>
          <button
            className={style.nav_item}
            onClick={() => navigate("/documents")}
          >
            Отчётные документы
          </button>
        </nav>
        {/* <nav className={style.navbar_wrapper}>
        <div
          className={
            menuActive === false
              ? `${style.header_menu}`
              : `${style.header_menu} ${style.active}`
          }
        >
          <ul className={style.header_list}>
            {currentPath === "/documents" ? (
              <>
                <li className={style.wrapper}>
                  <a href="/" className={style.text}>
                    Главная
                  </a>
                </li>
                <li className={style.wrapper}>
                  <a href="#info" className={style.text}>
                    Отчётные документы
                  </a>
                </li>
                <li className={style.wrapper}>
                  <a href="#contact" className={style.text}>
                    Связаться
                  </a>
                </li>
              </>
            ) : (
              <>
                {nav.map((item, index) => (
                  <NavbarItem key={index} link={item.link}>
                    {item.title}
                  </NavbarItem>
                ))}
              </>
            )}
          </ul>
        </div>
      </nav> */}
      </header>
      {renderContent()}
    </div>
  );
};

export default Navbar;
