import { useState, useEffect } from "react";

import Button from "../../Button/Button.tsx";

import style from "./SectionHome.module.scss";

import img_banner from "../../../img/img_banner.jpg";
import img_welcome from "../../../img/img_welcome.jpg";
import FileInput from "../../FileInput/FileInput.jsx";

import {admin, apiImageUrl} from "../../../App";

import axios from 'axios';

let timeoutId;

const SectionHome = () => {
  const [fio, setFio] = useState("");
  const [phone, setPhone] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [peoples, setPeoples] = useState("");
  const [rooms, setRooms] = useState("");

  const [text, setText] = useState('Загружаем..');
  const [imageLink, setImageLink] = useState("");
  const [imageLink2, setImageLink2] = useState("");
  const [fake, setFake] = useState("");

  const handleInputChange = (event) => {
    setText(event.target.value);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(sendData, 2000);
  };

  function sendData(block = 'Home') {
    if (fake.length) return;
    axios.post('/saveData', {
      block,
      text
    });
  }

  async function getData() {
    const {data} = await axios.get('/getData');
    setText(data.home.text);
    setImageLink(data.home.image);
    setImageLink2(data.home2.image);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={style.wrapper}>
      {!admin ? (
        <div className={style.banner_wrapper}>
          <img src={`${apiImageUrl}/uploads/${imageLink}`} className={style.img_welcome}/>
        </div>
      ) : (
        <div className={style.admin_banner_wrapper}>
          <div className={style.upload_file}>
            <FileInput block="home">
              <img src={`${apiImageUrl}/uploads/${imageLink}`} className={style.img_welcome}/>
            </FileInput>
          </div>
        </div>
      )}

      <div className={style.block_wrapper}>
        <div className={style.block_form}>
          <div className={style.title}>
            <span className={style.title_accent}>Забронировать</span>
          </div>
          <div className={style.input_wrapper}>
            <div className={style.input_item}>
              <input style={{display: 'none'}} onChange={(e) => setFake(e.target.value)}/>
              <div className={style.input_text_top}>Ф.И.О</div>
              <input className={style.input} defaultValue="Иванов Иван Иванович" type="text" value={fio} onChange={(e) => setFio(e.target.value)}/>
            </div>
            <div className={style.input_item}>
              <div className={style.input_text_top}>Телефон</div>
              <input className={style.input} type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>
            <div className={style.input_item}>
              <div className={style.input_text_top}>Дата заезда</div>
              <input className={style.input} type="date" onChange={(e) => setDateTo(e.target.value)}/>
            </div>
            <div className={style.input_item}>
              <div className={style.input_text_top}>Дата отъезда</div>
              <input className={style.input} type="date" onChange={(e) => setDateFrom(e.target.value)}/>
            </div>
            <div className={style.input_item_horizontal_wrapper}>
              <div className={style.input_item_horizontal}>
                <div className={style.input_text_top}>
                  Жильцы
                </div>
                <input
                  className={style.input}
                  type="number"
                  defaultValue="1"
                  min="1"
                  max="10"
                  value={peoples}
                  onChange={(e) => setPeoples(e.target.value)}
                />
              </div>
              <div className={style.input_item_horizontal}>
                <div className={style.input_text_top}>Комнаты</div>
                <input
                  className={style.input}
                  type="number"
                  defaultValue="1"
                  min="1"
                  max="10"
                  value={rooms}
                  onChange={(e) => setRooms(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className={style.button_wrapper}>
            <Button stretched mode="primary" onClick={() => {
              if (fake.length) return;
              axios.post('/sendMessage', {
                type: "1",
                dateTo: dateTo,
                dateFrom: dateFrom,
                peoplesCount: peoples,
                roomsCount: rooms,
                fio: fio,
                phone: phone
              });
            }}>
              Забронировать
            </Button>
          </div>
        </div>
        <div className={style.block_info}>
          <div className={style.title}>
            <span className={style.title_accent}>Добро</span> пожаловать
          </div>
          <div className={style.content_wrapper}>
            {!admin ? (
              <div className={style.img_wrapper}>
                <img src={`${apiImageUrl}/uploads/${imageLink2}`} className={style.img_welcome}/>
              </div>
            ) : (
              <div className={style.img_wrapper}>
                <div className={style.upload_file}>
                  <FileInput block="home2"/>
                </div>
              </div>
            )}
            {!admin ? (
              <div className={style.text}>
                {text}
              </div>
            ) : (
              <textarea className={style.admin_textarea} value={text} onChange={handleInputChange}>
                {text}
              </textarea>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHome;
