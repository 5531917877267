import { useState } from "react";

import Button from "../../components/Button/Button.tsx";

import style from "./Documents.module.scss";

import axios from "axios";

const Documents = () => {
  const [fio, setFio] = useState("");
  const [phone, setPhone] = useState("");
  const [fake, setFake] = useState("");

  return (
    <div className={style.wrapper}>
      <div className={style.text} id="info">
        Мы предлагаем комфортабельное проживание в Екатеринбурге с полным
        пакетом отчетных документов, необходимых для командировочных расходов. У
        нас вы можете получить чеки и отчетные документы по форме 3-Г, включая
        кассовый чек с QR-кодом, подтверждающий ваше пребывание в Екатеринбурге.
        <br />
        <br />
        Для отчетности по командировке в Екатеринбурге мы оформляем гостиничные
        чеки с подтверждением, товарные чеки, а также расходные и приходные
        ордера. Наши документы соответствуют всем требованиям бухгалтерии и
        подходят для отчетности.
        <br />
        <br />
        Пакет документов включает:
        <br />
        <br />
        Гостиничный чек с QR-кодом за проживание в Екатеринбурге
        <br />
        Счет за проживание в Екатеринбурге
        <br />
        Свидетельство
        <br />
        При необходимости: Акт, Прайс-лист, Справку подтверждения.
        <br />
        Если вам нужно сделать чек или получить кассовый чек с подтверждением
        для отчетности в бухгалтерии, мы предоставим чеки гостиницы с внесением
        данных в базу. Мы работаем исключительно в Екатеринбурге и гарантируем
        высокое качество документов.
        <br />
        <br />
        Мы также предлагаем услуги по оформлению чеки для отчетности при аренде
        квартиры посуточно в Екатеринбурге, включая чек и накладную. Для
        командировочных доступны чеки на проживание в гостинице с документами о
        проживании.
        <br />
        <br />
        Получить чеки в Екатеринбурге можно у нас быстро и недорого. Мы поможем
        вам оформить командировочные документы и предоставить отчетные документы
        для бухгалтерии, включая фискальные чеки с QR-кодом и гостиничные чеки в
        Екатеринбурге.
        <br />
        <br />
        Пишите или звоните, если вам нужно сделать чек об оплате или оформить
        другие финансово отчетные документы.
        <br />
        <br />
        Заказывайте у нас чеки с подтверждением для налоговой или бухгалтерии —
        мы обеспечим подготовку документов для всех ваших нужд в Екатеринбурге!
      </div>
      <div className={style.block_form} id="contact">
        <div className={style.title}>
          <span className={style.title_accent}>Заявка </span>
          обратной связи
        </div>
        <div className={style.input_wrapper}>
          <div className={style.input_item}>
            <input style={{display: 'none'}} onChange={(e) => setFake(e.target.value)}/>
            <div className={style.input_text_top}>Ф.И.О.</div>
            <input
              className={style.input}
              type="text"
              placeholder="Введите фамилию, имя и отчество"
              value={fio}
              onChange={(e) => setFio(e.target.value)}
            />
          </div>
          <div className={style.input_item}>
            <div className={style.input_text_top}>Телефон</div>
            <input
              className={style.input}
              type="tel"
              placeholder="Введите номер телефона"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className={style.button_wrapper}>
          <Button
            stretched
            mode="primary"
            onClick={() => {
              if (fake.length) return;
              axios.post("/sendMessage", {
                type: "2",
                fio: fio,
                phone: phone,
              });
            }}
          >
            Отправить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Documents;
