import React, { useRef, useState } from "react";

import style from "./FileInput.module.scss";
import axios from "axios";

const FileInput = (block) => {
  console.log(block);
  const inputRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);

  // Handle the change event when a file is selected
  const handleOnChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      console.log(event.target.files[0]);
      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0], "UTF-8");

      await axios.post("/upload", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'block': block.block
        }
      })
    }
  }

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const removeFile = () => {
    setSelectedFile(null);
  };

  return (
    <div className={style.wrapper}>
      {/* Hidden file input element */}
      <input
        type="file"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />

      {/* Button to trigger the file input dialog */}
      <button className={style.file_btn} onClick={onChooseFile}>
        <span class="material-symbols-rounded">Загрузка</span>Загрузить файл
      </button>

      {selectedFile && (
        <div className={style.selected_file}>
          <p>{selectedFile.name}</p>

          <button onClick={removeFile}>
            <span class="material-symbols-rounded">Удалить</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default FileInput;
