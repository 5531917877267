import {useEffect, useState} from "react";

import FileInput from "../../FileInput/FileInput";

import style from "./SectionAbout.module.scss";


import {admin, apiImageUrl} from "../../../App";
import axios from "axios";

let timeoutId;

const SectionAbout = () => {

  const [text, setText] = useState('Загружаем..');
  const [imageLink, setImageLink] = useState("");

  const handleInputChange = (event) => {
    setText(event.target.value);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(sendData, 2000);
  };

  function sendData(block = 'about') {
    axios.post('/saveData', {
      block,
      text
    });
  }

  async function getData() {
    const {data} = await axios.get('/getData');
    setText(data.about.text);
    setImageLink(data.about.image);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.title}>
        <span className={style.title_accent}>О</span> нас
      </div>
      <div className={style.content_wrapper}>
        {!admin ? (
          <div className={style.img_wrapper}>
            <img src={`${apiImageUrl}/uploads/${imageLink}`} />
          </div>
        ) : (
          <div className={style.admin_img_wrapper}>
            <div className={style.upload_file}>
              <FileInput block="about"/>
            </div>
          </div>
        )}
        {!admin ? (
          <div className={style.text}>
            {text}
          </div>
        ) : (
          <textarea className={style.admin_textarea} value={text} onChange={handleInputChange}>
            {text}
          </textarea>
        )}
      </div>
    </div>
  );
};

export default SectionAbout;
